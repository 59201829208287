<template lang="pug">
    .container.w-500
        add-user-post
</template>

<script>
//Components
import AddUserPost from '@/components/views/user-posts/add-user-post'

export default {
    name: "AddUserPost",
    components: {
        'add-user-post': AddUserPost,
    },
}
</script>