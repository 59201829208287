<template lang="pug">
    .container
        input(
            type="checkbox" 
            :name="name" 
            :checked="checked"
            @input="$emit('checked', $event.target.checked)"
        )
        label.ml-2(:for="name") {{ label }}
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        name: {
            type: String,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        }
    }
}
</script>