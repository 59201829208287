<template lang="pug">
    section
        text-input(:label="FORMS_LABELS.user_lastname" @value="form.lastname = $event" :value="form.lastname")
        text-input(:label="FORMS_LABELS.user_name" @value="form.firstname = $event" :value="form.firstname")
        text-input(:label="FORMS_LABELS.user_patronymic" @value="form.patronymic = $event" :value="form.patronymic")
        text-input(:label="FORMS_LABELS.date_of_birth" @value="form.date_of_birth = $event" :value="form.date_of_birth")
        text-input(:label="FORMS_LABELS.city" @value="form.city = $event" :value="form.city")
        text-input(:label="FORMS_LABELS.phone" @value="form.phone = $event" :value="form.phone")
        text-input(:label="FORMS_LABELS.email" @value="form.email = $event" :value="form.email")
        text-input(:label="FORMS_LABELS.title" @value="form.title = $event" :value="form.title")
        text-area.mb-3(:label="FORMS_LABELS.text" @value="form.text = $event" :value="form.text")
        file-drag-n-drop(@value="onFile")
        regular-button.w-32.mt-2(:value="BUTTONS_VALUES.save" @click="onSave")
</template>

<script>
//Components
import TextInput from '@/components/common/forms/text-input';
import TextArea from '@/components/common/forms/text-area';
import FileDragAndDrop from '@/components/common/forms/drag-n-drop-files';
import ChooseFile from '@/components/common/forms/choose-file';
import Checkbox from '@/components/common/forms/checkbox';
import RegularButton from '@/components/common/buttons/regular-button';
//Config
import { FORMS_LABELS, BUTTONS_VALUES } from '@/configs/names.js';
import { ICONS } from '@/configs/icons';
//Store
import { UserPostsNameSpace, UserPostsActionTypes } from '@/store/user-posts/types';
//Helpers
import { notifyDefaultError } from '../../../helpers/notifications';
//Mixins
import { _onFileUpload_ } from '@/mixins/files';

export default {
    name: "AddUserComponent",
    mixins: [_onFileUpload_],
    components: {
        'text-input': TextInput,
        'text-area': TextArea,
        'file-drag-n-drop': FileDragAndDrop,
        'choose-file': ChooseFile,
        'regular-button': RegularButton,
        'checkbox': Checkbox,
    },
    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                patronymic: '',
                date_of_birth: '',
                city: '',
                phone: '',
                email: '',
                title: '',
                text: '',
                attachment: [],
            }
        }
    },
    computed: {
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        ICONS() {
            return ICONS
        },
    },
    methods: {
        notifyDefaultError() {
            return notifyDefaultError()
        },
        onFile(value) {
            this.form.attachment = [...value.map(item => item.file_id)]
        },
        async onSave() {
            try {
                await this.$store.dispatch(`${UserPostsNameSpace}/${UserPostsActionTypes.AddUserPost}`, { 
                        ...this.form
                    }
                )
            } catch (error) {
                // this.notifyDefaultError({ error })
                console.log(error)
            }
        },
    }
}
</script>