<template lang="pug">
    .flex.flex-col.w-full
        .text-xs {{ FORMS_LABELS.attachment  }}
        .flex.flex-col
            .flex.flex-col(v-if="filelist.length")
                template(v-for="file in filelist")
                    .flex.h-30.w-full.p-2.bg-white.mb-5
                        .w-full.flex.justify-between
                            .flex.w-full
                                img.block.w-20.mr-10(:src="IMGS.doc_icon")    
                                .flex.flex-col.w-full
                                    .text-sm.mb-1
                                        span {{file.name}}
                                    .relative.w-full.h-progress-bar.bg-red-100
                                        .app-file-progress.absolute.bg-red-600.h-progress-bar.w-full
                            .flex.pl-10
                                img.cursor-pointer(:src="IMGS.close_icon" @click="remove(filelist.indexOf(file))")
            div
                .relative.w-full.h-200.border-dashed.border-2.bg-white.mb-10(
                    @dragover="dragover" 
                    @dragleave="dragleave" 
                    @drop="drop"
                )
                    .absolute.flex.justify-center.items-center.w-full.h-200
                        .flex.flex-col.items-center
                            img.block.cursor-pointer.mb-10(:src="IMGS.doc_icon" @click="onFile")
                            .text-sm.text-black.text-gray-400
                                span {{ FORMS_LABELS.grab_files }}
                        input(type="file" ref="file_upload" multiple style="display: none;" @input="onFileChange")
                    input(
                        type="file" 
                        name="fields[assetsFieldHandle][]" 
                        id="assetsFieldHandle" 
                        class="w-px h-px opacity-0 overflow-hidden absolute" 
                        @change="onChange" 
                        ref="file" 
                        accept=".pdf,.jpg,.jpeg,.png"
                    )
                //- .text-xs.text-gray-400(v-html="FORMS_LABELS.files_note")
</template>

<script>
//Store
import { FilesNameSpace, FilesActionTypes, FilesGetterTypes } from '@/store/files/types';
import { AuthorizationNameSpace, AuthorizationGetterTypes } from '@/store/authorization/types';
//Config
import { FORMS_LABELS } from '@/configs/names.js';
//Helpers
import { getUserAuthData } from '@/helpers/authorization';
//Helpers
import { notifyDefaultError } from '../../../helpers/notifications';

export default {
    name: "FileDragAndDrop",
    data() {
        return {
            filelist: [],
        }
    },
    computed: {
        IMGS() {
            return {
                doc_icon: require('./assets/doc.svg'),
                close_icon: require('./assets/close.svg'),
            }
        },
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        loadedFiles() {
            return this.$store.getters[`${FilesNameSpace}/${FilesGetterTypes.GetLoadedFiles}`]
        },
        userData() {
            return this.$store.getters[`${AuthorizationNameSpace}/${AuthorizationGetterTypes.GetUserAuthorizationData}`]
        }
    },
    methods: {
        notifyDefaultError() {
            return notifyDefaultError()
        },
        getUserAuthData() {
            return getUserAuthData()
        },
        onChange() {
            this.onLoadFile(this.$refs.file.files)
        },
        remove(i) {
            this.filelist.splice(i, 1);
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-gray-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-gray-300');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-gray-300');
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-gray-300');
        },
        onFile() {
            this.$refs['file_upload'].click()
        },
        onFileChange() {
            // this.filelist.push(...this.$refs['file_upload'].files)
             this.onLoadFile(this.$refs['file_upload'].files)
        },
        async onLoadFile(files) {
            try {
                const form_data = new FormData()
                form_data.append('files', ...files)
                const response = await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.AddFile}`, { files: form_data })
                if (!response.data.error_message) {
                    const { title, description } = this.$data
                    const { _id, name, type, size, path } = this.loadedFiles
                    await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.AddFileDetails}`, { 
                            details: {
                                title,
                                description,
                                file_id: _id,
                                user_id: this.getUserAuthData().id,
                                name,
                                type,
                                size,
                                path,
                            } 
                        }
                    )
                    
                    this.filelist.push(this.loadedFiles)
                }
            } catch (error) {
                this.notifyDefaultError({ error })
            }
        },
    },
    watch: {
        filelist: {
            handler(val) {
                this.$emit('value', val)
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss">
.app-file-progress {
    border-radius: 500px !important;
    box-shadow: 0px 1px 4px rgba(red, .5);
}
</style>